import styles from "./InsertText.module.css"
import React, { useContext, useEffect, useRef, useState } from "react";
import { playSound } from "../../utils/playsound";
import wait from "../../utils/wait";

import clsx from "clsx";
import { genColor, randomIntFromInterval } from "../../utils/common";
import { mockAudio, waitForVoice } from "../../utils/audios";
import BeatRead21 from "../../components/common/BeatRead21";
import FloatingObject from "../../components/common/FloatingObjects";
import { useInstruction } from "../../utils/instruction";
import BaseStage from "../interfaces/BaseStage";
import ItemTable from "../neutral/ItemTable";
import StageIntroduction from "../introduction/StageIntroduction";
import { Flipped, Flipper } from "react-flip-toolkit";
import RecordButton from "../neutral/RecordButton";
import Button from "../common/Button";
import UserContext from "../../context/UserContext";


function WordEditor({
    word,
    sylabicPos,
    cancelPos,
    validVowels,
    micStep,
    onNext,
    submitPart,
    stopTimer
}) {
    const [pState, setPState] = useState("1")
    const [vowels, setVowels] = useState([])
    const [sylableLine, setSylableLine] = useState()
    const [cancelLine, setCancelLine] = useState()

    const [dragTemporary, setDragTemporary] = useState(true)
    return <div className={styles.wordActions} >
        <span className={styles.word}>
            {
                word.split("").map((spl, inp) => {
                    return <><span onClick={async () => {
                        if (pState == "1") {
                            if (validVowels.indexOf(inp) != -1) {
                                var _addState = vowels || []

                                _addState.push(inp)
                                setVowels([..._addState])


                                if (validVowels.every(vl => (_addState.indexOf(vl) !== -1))) {
                                    setPState("2")
                                }
                            } else {

                            }
                        }

                    }
                    } className={clsx(styles.character, (cancelLine == inp) && styles.cancelled, ((pState != "1")) && styles.cancelStage, (pState == "1") && styles.hoverScalable, ((vowels?.indexOf(inp) !== -1)) && styles.correctChr)}
                        onDragOver={(event) => {
                            if (pState == "2") {
                                event.stopPropagation();
                                event.preventDefault();
                            }
                        }}
                        onDrop={async (e) => {
                            setDragTemporary(false)
                            if (inp == cancelPos) {

                                await wait(1000)
                                if (sylabicPos) {
                                    setPState("3")
                                } else {
                                    if (micStep) {
                                        micStep()
                                        setPState("4")
                                    } else {
                                        onNext()
                                    }
                                }

                            } else {

                                e.preventDefault()
                                setCancelLine(null)

                            }
                        }}
                        onDragEnter={() => {
                            if (pState == "2") {
                                setDragTemporary(true)
                                setCancelLine(inp)
                            }

                        }
                        }
                        onDragLeave={() => {
                            if ((dragTemporary) && (pState == "2")) {
                                setCancelLine(null)
                                setDragTemporary(true)
                            }
                        }}
                    >{spl}</span>
                        <div onDrop={async (e) => {
                            setDragTemporary(false)
                            if (inp == sylabicPos) {

                                await wait(1000)
                                if (micStep) {
                                    micStep()
                                    setPState("4")
                                } else {
                                    onNext()
                                }
                            } else {

                                e.preventDefault()
                                setSylableLine(null)

                            }
                        }
                        }
                            onDragOver={(event) => {
                                if (pState == "3") {
                                    event.stopPropagation();
                                    event.preventDefault();
                                }


                            }
                            }
                            onDragEnter={() => {

                                if (pState == "3") {
                                    setDragTemporary(true)
                                    setSylableLine(inp)
                                }
                            }
                            }

                            onDragLeave={() => {
                                if ((dragTemporary) && (pState == "3")) {

                                    setSylableLine(null)
                                    setDragTemporary(true)
                                }
                            }}
                            className={clsx(styles.theDropTarget, (sylableLine == inp) && styles.dragging)}></div>


                    </>
                })
            }
        </span>
        {(pState == "2") && <div draggable={true} className={clsx(styles.separatorLine, styles.cancellation)}><div className={styles.sln}></div></div>}
        {(pState == "3") && <div draggable={true} className={styles.separatorLine}><div className={styles.sln}></div></div>}
        {(pState == "4") && <div className={clsx(styles.micAndWord, styles.enabled)} style={{ flex: 0 }}>
            <RecordButton
                micEnabled={true}
                onClick={async () => {
                    const blob = await waitForVoice(word.length, 1, "waiting", 6000, { buttonMode: true });
                    //const blob = await mockBlend(word)
                    const result = await submitPart(blob, word);
                    if (result.score > 0.6) {

                    } else {

                    }
                    await wait(1000)
                    onNext(result.isCorrect);

                }}
            /></div>}
    </div>
}

class InsertText extends BaseStage {
    state = {
        clearInput: false,
    }

    render() {
        const { status, data, current, nextItem, nextPage, currentPage, lessonData, repeatId, trialId, countDown, stageDetails, windowSize: { width }, ageGroup } = this.props
        const isPageChanging = ((nextItem != null) && (nextItem !== current));
        const isGroupChanging = ((nextPage != null) && (nextPage !== currentPage));
        const { formSubmit, disabled, setText, playAgain } = this.props.controls.registerTextInput()
        const { type, content, focalIndex } = (stageDetails.background || {})
        const inputFlavour = stageDetails.inputFlavour;

        const chaoss= content.find(cols => cols.indexOf(current[0] + current.slice(2)) != -1);

        return <StageIntroduction stageDetails={stageDetails} onShown={async () => {
            if (stageDetails.uiBeforeSteps) {
                this.setState({ page: "lesson" })
            }
            await wait(1000)
            if (stageDetails.steps) {
                const { steps } = stageDetails;
                for (let i = 0; i < steps.length; i++) {
                    var step = steps[i]
                    if (step.type == "display") {
                        this.setState({ page: "display", content: step.content })
                        await wait(600)
                    } else if (step.type == "video") {
                        const promise = (new Promise((resolve, reject) => {
                            this.setState({
                                page: "video", content: step.content.replace("[[ageGroup]]", ageGroup), callback() {
                                    resolve()
                                }
                            })
                        }))
                        setTimeout(() => {
                            //check if video element which is id of videoPlayerItem exists, if it does, then play it
                            const videoElement = document.getElementById("videoPlayerItemGrp")
                            if (videoElement) {
                                videoElement.play();
                                // register an onclick event and if clicked, play the video
                                videoElement.onclick = function () {
                                    videoElement.play();
                                }
                            }
                        }, 500)
                        await promise
                    } else if (step.type == "audio") {
                        await soundManager.playSound("instruction", step.content)
                        await wait(step.afterDelay)
                    }

                }

            }
            if (!stageDetails.uiBeforeSteps) {
                this.setState({ page: "lesson" })
            }
            this.props.controls.initiateQuestions()
        }}>

            <div className={clsx(styles.titleContainer)}>
                {stageDetails.title && <h1 className={styles.title}>{stageDetails.title}</h1>}
                {
                    this.state.page === "video" && <>
                        <video id={"videoPlayerItemGrp"} autoPlay={true} autoBuffer={true} controls={true}
                            src={this.state.content} className={styles.videoPresentation} onEnded={() => {
                                this.state.callback()
                            }
                            } />
                    </>
                }
                {
                    this.state.page == "lesson" && <>
                        {
                            type === "contentGrid" && <div className={(styles.contentGrid)}>
                                {content.map(item => {
                                    return <span key={item} className={styles.item}>{item}</span>
                                })}
                            </div>
                        }
                        {
                            type === "options" && <div className={(styles.options)}>
                                {
                                    content.map((oI, i) => {
                                        return <>
                                            {(i > 0) && <span className={styles.plus}>+</span>}
                                            {
                                                (typeof oI[0] == "string") ? <div className={styles.leanOption}>
                                                    {
                                                        oI.map(otm => <span>{otm}</span>)
                                                    }
                                                </div> : <ItemTable unboxed={true} smallest={true} tableMap={oI} colored={i == focalIndex} />
                                            }
                                        </>
                                    })
                                }
                            </div>
                        }

                        {
                            type === "chaos" && <div className={(styles.chaosContainer)}>
                                {
                                    content.map(ky => {
                                        return <div onClick={async () => {
                                            //get previous text
                                            if (this.state.selectionStatus?.[lessonData[ky]?.text]) {
                                                const prevText = document.getElementById("clearableInputText")?.value ?? "";
                                                var result = prevText.split(" ").filter(it => it != lessonData[ky]?.text).join(" ")
                                                window?.updateLessonInputText?.(result)
                                            } else {
                                                const prevText = document.getElementById("clearableInputText")?.value ?? "";

                                                var arrays = []
                                                if (prevText.length > 0) {
                                                    arrays.push(prevText)
                                                }

                                                arrays.push(lessonData[ky]?.text)

                                                var resultText = arrays.join(" ")
                                                window?.updateLessonInputText?.(resultText)
                                            }
                                        }} className={clsx(styles.chaosItem, this.state.selectionStatus?.[lessonData[ky]?.text] && styles.selected)}>{lessonData[ky]?.text}</div>
                                    })
                                }
                                <div>

                                </div>


                            </div>
                        }
                        {
                            type === "chaoss" && <div className={(styles.chaosContainer)}>
                                {
                                    chaoss.map(ky => {
                                        return <div onClick={async () => {
                                            //get previous text
                                            if (this.state.selectionStatus?.[lessonData[ky]?.text]) {
                                                const prevText = document.getElementById("clearableInputText")?.value ?? "";
                                                var result = prevText.split(" ").filter(it => it != lessonData[ky]?.text).join(" ")
                                                window?.updateLessonInputText?.(result)
                                            } else {
                                                const prevText = document.getElementById("clearableInputText")?.value ?? "";

                                                var arrays = []
                                                if (prevText.length > 0) {
                                                    arrays.push(prevText)
                                                }

                                                arrays.push(lessonData[ky]?.text)

                                                var resultText = arrays.join(" ")
                                                window?.updateLessonInputText?.(resultText)
                                            }
                                        }} className={clsx(styles.chaosItem, this.state.selectionStatus?.[lessonData[ky]?.text] && styles.selected)}>{lessonData[ky]?.text}</div>
                                    })
                                }
                                <div>

                                </div>


                            </div>
                        }




                        <div className={styles.editorContainer}>
                            <form onSubmit={() => {
                                formSubmit().then(() => window?.updateLessonInputText?.(""))


                            }} action="javascript:void(0);" className={styles.textWriter}>
                                {
                                    (!stageDetails.noItemTitle && type !== "chaoss")&& <span className={styles.wordIndex}>{stageDetails?.inputPrefix || "Word"} {current.replace(/[^0-9]+/ig, "")}</span>

                                }
                               { (type !== "chaoss")&& <div className={styles.inputBox} style={{ border: 0, padding: 0 }}>
                                    {
                                        (inputFlavour === "preInserted") && <span style={{ fontWeight: "bold" }}  >{data[current].text}</span>
                                    }

                                    <InputBoxContainer updateItemActivity={(selectionStatus) => {
                                        this.setState({ selectionStatus })
                                    }
                                    } current={current} setText={setText} repeatId={repeatId} key={current + (String(repeatId))} clearInput={this.state.clearInput} setClearInput={(st) => {
                                        this.setState({ clearInput: st })
                                    }} />
                                </div>}
                                <div style={{ display: "flex", flexDirection: "row", gap: 10 }}>
                                    <Button fillWidth onClick={async () => {
                                        await playAgain()
                                    }} title={`Repeat`} type={"button"} icon={"speaker"}>Repeat</Button>
                                    <Button fillWidth isDisabled={disabled} direction="left" type={"submit"} icon={"nextarrow"} border={"black"} background={"green"} title={`Submit`} />
                                </div>

                            </form>

                        </div>
                    </>
                }



            </div>
            <FloatingObject color={"rgba(78,84,200, 0.2)"} status={status} isHiding={isGroupChanging} />
            <BeatRead21 />
        </StageIntroduction>
    }
}

function InputBoxContainer({ setText: _setText, repeatId, current, updateItemActivity, clearInput, setClearInput }) {
    const [text, setText] = useState("")
    /**
     *
     * @type {React.MutableRefObject<HTMLInputElement>}
     */
    const inputRef = useRef();

    useEffect(() => {

        _setText(text)
        if (text?.length > 0) {
            var resultItem = {}
            for (let i = 0; i < text.split(" ").length; i++) {
                const t = text.split(" ")[i];
                resultItem[t] = true
            }
            updateItemActivity(resultItem)

        } else {
            updateItemActivity({})
        }
    }, [text, repeatId])

    useEffect(() => {
        inputRef.current.focus()
        setText("")
    }, [current]);

    useEffect(() => {
        window.updateLessonInputText = (text) => {
            setText(text)
        }
    }, [])

    useEffect(() => {
        if (clearInput) {
            setClearInput(false)
            setText("")
        }
    }, [clearInput])


    return <div className={styles.inputBox}>
        <input ref={inputRef} id={"clearableInputText"} placeholder={"Type here..."} style={{ fontWeight: "bold" }} autoFocus={true} onChange={(e) => setText(e.target.value)} value={text} />
    </div>

}

function withMyHook(Component) {
    return function WrappedComponent(props) {
        const { user } = useContext(UserContext);
        useInstruction(props.stageDetails?.instruction && [props.stageDetails?.instruction]);
        return <Component {...props} ageGroup={
            user.grade ? (Number.parseInt(user.grade) > 5 ? "older" : "younger") : "older"
        } />;
    }
}


export default withMyHook(InsertText)
